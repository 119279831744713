exports.components = {
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-mdx-slug-tsx": () => import("./../../../src/pages/blog/{mdx.slug}.tsx" /* webpackChunkName: "component---src-pages-blog-mdx-slug-tsx" */),
  "component---src-pages-heightfield-tsx": () => import("./../../../src/pages/heightfield.tsx" /* webpackChunkName: "component---src-pages-heightfield-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */),
  "component---src-pages-wip-tsx": () => import("./../../../src/pages/wip.tsx" /* webpackChunkName: "component---src-pages-wip-tsx" */),
  "component---src-pages-working-tsx": () => import("./../../../src/pages/working.tsx" /* webpackChunkName: "component---src-pages-working-tsx" */)
}

