import React from "react"

export enum WhatTheme {
  THEME = "theme",
  CODE_THEME = "code-theme",
}

export enum Themes {
  DARK = "theme-dark",
  LIGHT = "theme-light",
}

export enum CodeThemes {
  // DARK = "code-dark",
  // LIGHT = "code-light",
  DARK = "theme-dark",
  LIGHT = "theme-light",
}

export function swapTheme(
  updateTheme: React.Dispatch<React.SetStateAction<string>>
) {
  if (!updateTheme) return
  if (getTheme(WhatTheme.THEME) === Themes.DARK) {
    setTheme(WhatTheme.THEME, Themes.LIGHT)
    updateTheme(Themes.LIGHT)
  } else if (getTheme(WhatTheme.THEME) === Themes.LIGHT) {
    setTheme(WhatTheme.THEME, Themes.DARK)
    updateTheme(Themes.DARK)
  }
}

export function getTheme(whatTheme: WhatTheme) {
  if (typeof window !== "undefined") {
    return window.localStorage.getItem(whatTheme)
  }
}

export function setTheme(whatTheme: WhatTheme, themeName: string) {
  if (typeof window !== "undefined") {
    window.localStorage.setItem(whatTheme, themeName)
    if (whatTheme === WhatTheme.THEME) {
      document.documentElement.className = themeName //+ getTheme(WhatTheme.CODE_THEME)
    } else {
      // document.documentElement.className = themeName + getTheme(WhatTheme.THEME)
    }
  }
}

export function loadTheme(whatTheme: WhatTheme) {
  if (typeof window !== "undefined") {
    if (whatTheme === WhatTheme.CODE_THEME) {
      let theme = window.localStorage.getItem(whatTheme)
      if (Object.values<string>(CodeThemes).includes(theme)) {
        setTheme(whatTheme, theme)
      } else {
        setTheme(whatTheme, CodeThemes.DARK)
      }
    } else {
      let theme = window.localStorage.getItem("theme")
      if (theme === Themes.DARK) {
        setTheme(whatTheme, Themes.DARK)
      } else if (theme === Themes.LIGHT) {
        setTheme(whatTheme, Themes.LIGHT)
      } else {
        if (
          window.matchMedia &&
          window.matchMedia("(prefers-color-scheme: dark)").matches
        ) {
          setTheme(whatTheme, Themes.DARK)
        } else {
          setTheme(whatTheme, Themes.LIGHT)
        }
      }
    }
  }
}

export function hexToRgb(
  hex: string
): { r: number; g: number; b: number } | null {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}

export function getTextColor(bgColor, lightColor, darkColor) {
  var color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor
  var r = parseInt(color.substring(0, 2), 16) // hexToR
  var g = parseInt(color.substring(2, 4), 16) // hexToG
  var b = parseInt(color.substring(4, 6), 16) // hexToB
  var uicolors = [r / 255, g / 255, b / 255]
  var c = uicolors.map(col => {
    if (col <= 0.03928) {
      return col / 12.92
    }
    return Math.pow((col + 0.055) / 1.055, 2.4)
  })
  var L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2]
  return L > 0.179 ? darkColor : lightColor
  // return L > 0.5 ? darkColor : lightColor
}
