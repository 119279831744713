import React, { createContext, useEffect, useMemo, useState } from "react"
import {
  CodeThemes,
  getTheme,
  loadTheme,
  Themes,
  WhatTheme,
} from "../utils/themes"

export type Context = {
  theme: string
  updateTheme: React.Dispatch<React.SetStateAction<string>>
  codeTheme: string
  updateCodeTheme: React.Dispatch<React.SetStateAction<string>>
}

export const ThemeContext = createContext<Context>({
  theme: undefined,
  updateTheme: () => {},
  codeTheme: undefined,
  updateCodeTheme: () => {},
})

const ThemeProvider = ({ children }) => {
  const [theme, updateTheme] = useState<string>(undefined)
  const [codeTheme, updateCodeTheme] = useState<string>(undefined)

  const value = useMemo(
    () => ({ theme, updateTheme, codeTheme, updateCodeTheme }),
    [theme, codeTheme]
  )

  useEffect(() => {
    loadTheme(WhatTheme.THEME)
    updateTheme(getTheme(WhatTheme.THEME))

    loadTheme(WhatTheme.CODE_THEME)
    updateCodeTheme(getTheme(WhatTheme.CODE_THEME))
  })

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
}

export default ThemeProvider
